.window {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  /* height: 478px; */
  width: 813px;
  position: fixed;
  padding-top: 40px;
  padding-bottom: 40px;
  margin: 0;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
.windowHead {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 15px;
  border-bottom: 1px solid #000;
  margin-bottom: 15px;
}
.windowHead img {
  cursor: pointer;
}
.button {
  text-align: center;
}
.windowHead span {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 148.69%;
}
.windowHead h2 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 148.69%;
  text-align: left;
  margin-bottom: 5px;
}
.formBox {
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 30px;
}
.windowForm {
  display: flex;
  flex-direction: column;
}
.formBox div {
  display: flex;
  flex-direction: column;
}
.formBox div {
  display: flex;
  flex-direction: column;
}
.windowForm label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
.windowForm input {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-top: 10px;
  border: none;
  height: 20px;
  padding: 15px 20px 15px 20px;
}
.windowForm::placeholder {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #d9d9d9;
}

.formButton1 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
  color: #008d0e;
  padding: 20px 25px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  cursor: default;
  opacity: 0.5;
}

@media screen and (max-width: 850px) {
  .window {
    width: 90%;
  }
}
@media screen and (max-width: 550px) {
  .wrapperWindow {
    height: 480px;
    overflow: auto;
    padding: 5px;
  }
  .formBox {
    grid-template-columns: repeat(1, 1fr);
  }
  .windowHead {
    flex-direction: column;
    row-gap: 20px;
  }
  .formButton1 {
    font-size: 16px;
  }
}
