.form {
  margin-top: 50px;
  margin-bottom: 130px;
}
.form h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 65px;
  text-align: center;
}
/* .formBox {
  display: grid;
 
  column-gap: 20px;
  row-gap: 30px;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 30px;
}

.formBox2 {
  display: grid;
  column-gap: 20px;
  row-gap: 40px;
  grid-template-columns: repeat(2, 1fr);
} */
.box {
  display: flex;
  flex-direction: column;
}
.box2 {
  display: flex;
  flex-direction: column;
}

.formBlocks label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  white-space: nowrap;
}
.formBlocks input {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-top: 10px;
  border: none;
  height: 20px;
  padding: 15px 20px 15px 20px;
}
.formBlocks::placeholder {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #d9d9d9;
}
/* .formBlock {
  margin-bottom: 38px;
} */
.language {
  height: auto !important;
  background: transparent !important;
  padding: 0 !important;
  box-shadow: none !important;
  margin-right: 15px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
}
input:focus {
  outline: none;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

/* .formBlock {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
} */
.checkBox {
  display: flex;
  column-gap: 50px;
  margin-top: 25px;
}
/* input[type="radio"] {
  color: #000;
  background: #000000;
  border: 1px solid #000000;
  margin-right: 15px;
  height: auto;
  margin-top: 0;
  margin-left: 0;
} */
.radioBlock {
  display: flex;
  align-items: center;
  height: 50px;
  column-gap: 50px;
  margin-top: 10px;
}
/* .result {
  margin-top: 40px;
} */
.resBox {
  display: flex;
  align-items: baseline;
  margin-top: 7px;
  margin-bottom: 0;
}
.resBox h5 {
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  color: #008d0e;
  margin-left: 10px;
}
.result h2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  color: #008d0e;
}
.formButton {
  display: flex;
  justify-content: center;
  column-gap: 20px;
}

.formButton1 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
  color: #008d0e;
  padding: 20px 25px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  /* cursor: default;
  opacity: 0.5; */
}
.formButton2 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
  color: #cf3336;
  padding: 20px 25px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  /* cursor: default;
  opacity: 0.5; */
}
/* .lang2 {
  display: none;
} */
.formBlocks {
  margin-bottom: 40px;
}

.block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  margin-bottom: 30px;
}
.formBox_1 {
  display: grid;
  column-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
}
.formDate {
  display: grid;
  column-gap: 20px;
  margin-bottom: 30px;
  grid-template-columns: repeat(2, 1fr);
}
.formBox_2 {
  display: grid;
  column-gap: 20px;

  grid-template-columns: repeat(2, 1fr);
}
.formUserName {
  display: grid;
  column-gap: 20px;

  grid-template-columns: repeat(2, 1fr);
}

/* Media */

@media screen and (max-width: 850px) {
  /* .lang2 {
    display: block;
  }
  .lang {
    display: none;
  } */
  .result h5 {
    line-height: 47px;
  }
  .result h2 {
    font-size: 40px;
    line-height: 47px;
  }

  .formBlocks label {
    font-size: 14px;
  }
  .formBlocks input {
    height: auto;
  }
  .form h1 {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 750px) {
  .box {
    width: 100%;
  }
  .box2 {
    width: 100%;
  }

  .form h1 {
    font-size: 24px;
    line-height: 28px;
  }
  .form {
    margin-top: 93px;
  }
  .block {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    row-gap: 20px;
    margin-bottom: 20px;
  }
  .formBox_1 {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 20px;
    margin-bottom: 20px;
  }
  .formDate {
    justify-items: center;
    row-gap: 20px;
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 0;
  }
  .formBox_2 {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 20px;
  }
  .formUserName {
    justify-items: center;
    row-gap: 20px;
    grid-template-columns: repeat(1, 1fr);
  }

  /* .formBox {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    width: 100%;
    row-gap: 20px;
  }
  .formBlock {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    row-gap: 20px;
    margin-bottom: 20px;
  }
  .formBox2 {
    grid-template-columns: repeat(1, 1fr);
    justify-items: start;
    width: 580px;
    row-gap: 20px;
  }
  */
}
@media screen and (max-width: 580px) {
  .formButton {
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
  }
  .formButton button {
    width: 290px;
  }
  .form {
    margin-bottom: 32px;
  }
}
@media screen and (max-width: 420px) {
  .formButton button {
    width: 100%;
    font-size: 16px;
  }

  .result {
    margin-top: 20px;
  }
}
