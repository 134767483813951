.window {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  /* height: 478px; */
  /* width: 813px; */
  width: 550px;
  position: fixed;
  padding-top: 40px;
  padding-bottom: 40px;
  margin: 0;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
.windowHead {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: 20px;
}
.windowHead img {
  cursor: pointer;
}
.headText {
  text-align: center;
  margin-bottom: 20px;
}
.headText h1 {
  font-weight: 400;
  font-size: 32px;
  text-transform: uppercase;
  color: #414141;
}
.polisBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}
.polisBlock button {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  /* padding: 20px 45px; */
  height: 70px;
  width: 250px;
  /* margin-top: 20px; */
  opacity: 0.5;
  cursor: default;
  color: #008d0e;
}

.polisBlock input {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  border: none;
  width: 430px;
  padding: 15px 20px 15px 20px;
}
.polisBlock::placeholder {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #d9d9d9;
}

@media screen and (max-width: 550px) {
  .window {
    width: 90%;
  }
  .polisBlock input {
    width: 80%;
  }
  .headText h1 {
    font-size: 25px;
  }
  .polisBlock button {
    font-size: 16px;
    width: 200px;
    height: 60px;
  }
}
