.footer {
  background: #102437;
  text-align: center;
  margin-top: 150px;
}
.footer h3 {
  padding: 20px 0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
}

@media screen and (max-width: 750px) {
  .footer h3 {
    font-size: 14px;
    padding: 20px;
  }
  .footer {
    margin-top: 20px;
  }
}
