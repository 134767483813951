.window {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  /* height: 478px; */
  /* width: 813px; */
  width: 550px;
  position: fixed;
  padding-top: 40px;
  padding-bottom: 40px;
  margin: 0;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
.windowHead {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}
.windowHead img {
  cursor: pointer;
  padding-right: 25px;
}
.windowHead h1 {
  font-weight: 400;
  font-size: 32px;
  text-transform: uppercase;
  color: #414141;
}
.resultContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
}
.resultContent button {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  height: 70px;
  width: 250px;
  cursor: pointer;
  color: #008d0e;
}

.result h2 {
  color: #008d0e;
  font-size: 45px;
}
.result span {
  color: #000;
  font-weight: 400;
}

@media screen and (max-width: 600px) {
  .window {
    width: 90%;
  }
}
@media screen and (max-width: 400px) {
  .headText h1 {
    font-size: 25px;
  }
  .resultContent button {
    font-size: 16px;
    width: 200px;
    height: 60px;
  }
}
