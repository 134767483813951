.driversForm {
  margin-bottom: 50px;
}
.drivers {
  margin: 30px 0;
}
.headText {
  margin-bottom: 20px;
}
.headText h1 {
  font-size: 16px;
  /* font-weight: 700; */
}
.input {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.labelInput {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  padding: 0 20px;
  margin-bottom: 10px;
}
.labelInput h1 {
  font-size: 16px;
  font-weight: 400;
}
.labelInput div:first-child {
  width: 450px;
}
.inputBlock {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
}
.item input {
  padding: 15px 0;
  border: 0;
  width: 100%;
  background: transparent;
}
.item input::placeholder {
  color: #d9d9d9;
  font-size: 16px;
}
.box h1 {
  display: none;
}
.driverInput {
  padding: 0 20px;
}
.driverInputSecond {
  background: #f9f9f9;
  padding: 0 20px;
}
.driverInputSecond input {
  background: #f9f9f9;
}
.firstForm {
  width: 450px;
}
.radioContent {
  display: flex;
  justify-content: center;
}
.radioBlock {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.radioBlock h1 {
  font-size: 16px;
  font-weight: 400;
}
.radioBox {
  display: flex;
  column-gap: 20px;
}
.radioBox div {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
.radioBlock input {
  margin: 0;
}
@media screen and (max-width: 900px) {
  .firstForm {
    width: 300px;
  }
  .labelInput div:first-child {
    width: 300px;
  }
}
@media screen and (max-width: 800px) {
  .firstForm {
    width: 200px;
  }

  .inputBlock {
    grid-template-columns: repeat(1, 1fr);
  }
  .box {
    display: flex;
    align-items: center;
    column-gap: 30px;
  }
  .box h1 {
    display: block;
    font-size: 14px;
    font-weight: 400;
    width: 110px;
  }

  .labelInput {
    display: none;
  }
  /* .box input[type="date"]::-webkit-calendar-picker-indicator {
    display: block;
  } */
}
@media screen and (max-width: 500px) {
  .inputBlock {
    margin-bottom: 20px;
  }
  .input {
    height: auto;
    overflow-y: auto;
  }
  .firstForm {
    width: auto;
  }
}

@media screen and (max-width: 350px) {
  .box h1 {
    width: 60px;
  }
}





/* новое */
.checkbox-container {
  display: flex;
  align-items: center; /* Выравнивание по вертикали */
  gap: 10px; /* Отступ между чекбоксом и текстом */
  padding-top: 30px;
}

.checkbox-container input[type="checkbox"] {
  width: 20px; /* Размер чекбокса */
  height: 20px;
  margin: 0; /* Убираем отступы */
}

.checkbox-container label {
  font-size: 16px; /* Размер шрифта */
  color: #333; /* Цвет текста */
  cursor: pointer; /* Курсор, когда наводим на текст */
}

.checkbox-container input[type="checkbox"]:checked + label {
  font-weight: bold; /* Жирный текст, если чекбокс выбран */
}

.checkbox-container input[type="checkbox"]:focus {
  outline: none; /* Убираем стандартное выделение при фокусе */
}
