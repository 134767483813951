.resultBlock {
  margin: 30px;
}
.result {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 20px;
}
.number {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.result h1 {
  font-size: 16px;
}
.result h2 {
  font-weight: 700;
  font-size: 40px;
  color: #008d0e;
}
.result span {
  font-weight: 400;
  font-size: 30px;
  color: #000;
}
.number h1 {
  font-size: 16px;
  font-weight: 400;
}
.number h2 {
  font-size: 40px;
  color: grey;
  font-weight: 400;
}
@media screen and (max-width: 750px) {
  .result {
    flex-direction: column;
    align-items: center;
    row-gap: 5px;
  }
  .number {
    flex-direction: column;
    align-items: center;
    row-gap: 5px;
  }
}
