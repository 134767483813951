.insurance {
  position: relative;
}
.background {
  background-image: url(../image/Star_of_life2.svg);
  background-repeat: no-repeat;
  width: 100%;
  height: 1180px;
  position: absolute;
  right: 0;
  max-width: 50%;
  background-size: contain;
  top: -5%;
  z-index: -1;
}
.mobileImg {
  position: relative;
}
.mobileImg img:first-child {
  max-width: 100%;
  width: 90%;
}
.mobileImg img:last-child {
  position: absolute;
  left: 40%;
  top: -5%;
  max-width: 100%;
  width: 100%;
}
.insurance h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 50px;
}
.insuranceBlock {
  display: flex;
  justify-content: space-around;
  margin-bottom: 145px;
}
.insuranceText p {
  width: 400px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 25px;
}

.appMarket {
  display: flex;
  column-gap: 20px;
}
.polisBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 140px;
}
.polisBlock input {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  border: none;
  height: 20px;
  width: 480px;
  padding: 15px 20px 15px 20px;
  margin-bottom: 25px;
}

.polisBlock::placeholder {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #d9d9d9;
}

.polisBlock button {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 20px 45px;
  margin-top: 20px;
  opacity: 0.5;
  cursor: default;
  color: #008d0e;
}
.pdf div:last-child {
  margin-bottom: 0;
}

.pdf h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
  margin-right: 70px;
  width: 65%;
}
.pdf a {
  color: #000;
}
.pdf a:hover {
  color: rgb(15, 15, 142);
}
.pdf div:nth-child(2) {
  border-bottom: 2px solid #000;
  padding-bottom: 35px;
}
.pdf div:nth-child(4) {
  border-bottom: 2px solid #000;
  padding-bottom: 35px;
}
.pdf div:nth-child(6) {
  border-bottom: 2px solid #000;
  padding-bottom: 35px;
}
.pdf div {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  align-items: center;
}
.marketBlock {
  display: none;
}

/* Media */

@media screen and (max-width: 1140px) {
  .insuranceBlock {
    flex-direction: column;
    align-items: center;
    row-gap: 50px;
    margin-bottom: 50px;
  }
  .insuranceText p {
    width: 100%;
  }
  .appMarket {
    display: none;
  }
  .marketBlock {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    justify-content: center;
  }
  .marketBlock img {
    width: 80%;
    max-width: 100%;
  }
  .mobiMarket {
    display: flex;
  }
}
@media screen and (max-width: 850px) {
  .background {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .insurance h1 {
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 28px;
  }
  /* .polisBlock input {
    width: 100%;
    padding: 11px 10px 12px 10px;
  } */
  .polisBlock {
    margin-bottom: 50px;
  }
  .insuranceText p {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 19px;
  }

  .insuranceBlock {
    row-gap: 35px;
  }

  .pdf h2 {
    font-size: 16px;
    margin-right: 30px;
    width: 100%;
    line-height: 19px;
  }

  .pdf img {
    width: 80%;
  }

  .mobileImg img:first-child {
    width: 50%;
  }
  .mobileImg img:last-child {
    width: 55%;
    position: absolute;
    left: 20%;
    top: -5%;
    max-width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .polisBlock input {
    width: 80%;
  }
}
@media screen and (max-width: 420px) {
  .polisBlock button {
    font-size: 16px;
    padding: 20px;
  }
  .background {
    top: 750%;
  }
}
