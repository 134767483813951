.transport {
  margin: 30px 0;
}
.radioBlock {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  align-items: center;
  column-gap: 40px;
  row-gap: 20px;
}
.radioBlock input {
  margin-right: 16px;
}

.headText {
  margin-bottom: 20px;
}
.headText h1 {
  font-size: 16px;
  font-weight: 700;
}
.radioBlock div {
  display: flex;
}
.inputBlock {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  margin-top: 30px;
}
.box {
  display: flex;
  flex-direction: column;
}
.inputBlock input {
  padding: 15px 20px 15px 20px;
  border: 0;
  margin-top: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.box input::placeholder {
  color: #d9d9d9;
}
@media screen and (max-width: 1100px) {
  .radioBlock {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media screen and (max-width: 800px) {
  .inputBlock {
    grid-template-columns: repeat(2, 1fr);
  }
  .radioBlock {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 500px) {
  .radioBlock {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 400px) {
  .inputBlock {
    grid-template-columns: repeat(1, 1fr);
  }
  .radioBlock {
    grid-template-columns: repeat(1, 1fr);
  }
}
