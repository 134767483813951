.window {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  height: 500px;
  width: 800px;
  position: fixed;
  padding-top: 40px;
  padding-bottom: 40px;
  margin: 0;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
.windowHead {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 0 15px 0;
  border-bottom: 1px solid #000;
  margin-bottom: 20px;
  position: sticky;
  left: 0%;
}
.windowHead img {
  cursor: pointer;
}
.windowText h1 {
  font-weight: 400;
  font-size: 32px;
  text-transform: uppercase;
  margin-bottom: 0;
  text-align: left;
  line-height: normal;
}
.policies {
  overflow: auto;
}

.table {
  overflow: auto;
  height: 450px;
}

.table button {
  cursor: pointer;
  font-size: 15px;
  color: rgb(0, 0, 187);
}

@media screen and (max-width: 850px) {
  .window {
    width: 550px;
  }
}

@media screen and (max-width: 550px) {
  .window {
    width: 100%;
  }
  .windowHead {
    flex-direction: column;
    row-gap: 20px;
  }
  .windowHead h1 {
    font-size: 20px;
  }
}
