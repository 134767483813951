.window {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  /* height: 478px; */
  /* width: 813px; */
  width: 800px;
  position: fixed;
  padding-top: 40px;
  padding-bottom: 40px;
  margin: 0;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
.polis {
  height: 550px;
  overflow: auto;
  padding: 5px;
}
.windowHead {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}
.windowHead img {
  cursor: pointer;
}
.windowHead h1 {
  font-weight: 400;
  font-size: 32px;
  text-transform: uppercase;
  color: #414141;
}
.windowHead span {
  color: grey;
  font-weight: 700;
}
.headText {
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 2px solid #ccc;
}
.headText h1 {
  font-size: 20px;
  font-weight: 400;
}
.data {
  margin-bottom: 20px;
}
.dataBlock {
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
}
.dataBlock_2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
}
.user {
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
}
.polisNum {
  display: flex;
  align-items: baseline;
  background: #fff;
  border-radius: 8px;
  width: 90%;
  padding: 15px 0 15px 20px;
}
.polisNum p {
  font-weight: 400;
  font-size: 14px;
  /* color: #d9d9d9; */
}
.polisNum input {
  padding: 0 0 0 5px !important;
  border-radius: 0 !important;
  width: 80% !important;
}
.dataItem {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  background: #e9e9e9;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
}
.data input::placeholder {
  color: #d9d9d9;
  font-size: 14px;
}
.dataItem:last-child {
  margin-bottom: 0;
}
.dataItemCarType {
  margin-bottom: 0;
}
.radioBlock {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 30px;
  margin-bottom: 30px;
  padding-top: 15px;
}
.radioBox {
  background: #e9e9e9;
  padding: 20px;
  border-radius: 8px;
}
.radioBox h1 {
  font-size: 16px;
  font-weight: 400;
}
.radioItem {
  display: flex;
  column-gap: 20px;
  margin-top: 10px;
}
.radioItem div {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
.radioItem input {
  margin: 0;
  padding: 0;
}
.data label {
  font-size: 14px;
}

.data input {
  background: #ffffff;
  border-radius: 8px;
  border: none;
  padding: 15px 0 15px 20px;
}
.dataContent {
  margin-bottom: 30px;
}

.radio input {
  width: auto;
  padding: 0;
  margin: 0 10px 0 0;
}
.result {
  display: flex;
  column-gap: 20px;
  align-items: baseline;
  margin-bottom: 20px;
}
.result h1 {
  font-size: 40px;
  font-weight: 400;
}
.result h2 {
  color: #008d0e;
  font-size: 35px;
}
.result span {
  color: #000;
  font-weight: 400;
}

/* .dataItem:last-child {
  margin-bottom: 0;
} */
/* .driversBlock {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;
} */
/* driver */
.numDriver {
  margin-bottom: 10px;
}
.numDriver h1 {
  font-size: 18px;
  font-weight: 500;
}
.driversBlock {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 30px;
  margin-bottom: 30px;
}
.driversBox {
  background: #e9e9e9;
  padding: 15px;
  border-radius: 8px;
}

.driversItem {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-bottom: 20px;
}
.driversItem:last-child {
  margin-bottom: 0;
}

.btn {
  text-align: center;
}
.btn button {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
  color: #008d0e;
  padding: 20px 25px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  cursor: pointer;
}

@media screen and (max-width: 850px) {
  .window {
    width: 550px;
  }
  .data input[type="text" i] {
    padding: 12px 0 12px 15px;
  }
}
@media screen and (max-width: 700px) {
  .windowHead {
    flex-direction: column;
    row-gap: 20px;
  }
}
@media screen and (max-width: 600px) {
  .window {
    width: 400px;
  }
  .dataBlock {
    grid-template-columns: repeat(1, 1fr);
  }
  .dataBlock_2 {
    grid-template-columns: repeat(1, 1fr);
  }
  .radioBlock {
    grid-template-columns: repeat(1, 1fr);
  }
  .dataItemCarType {
    margin-bottom: 30px;
  }
  .dataItem:last-child {
    margin-bottom: 30px;
  }
  .driversBlock {
    grid-template-columns: repeat(1, 1fr);
  }
  .user {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media screen and (max-width: 500px) {
  .btn button {
    font-size: 16px;
  }
  .windowHead h1 {
    font-size: 25px;
  }
}
@media screen and (max-width: 450px) {
  .window {
    width: 300px;
  }
}
