.headText {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 60px;
}
.headText h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  text-transform: uppercase;
  margin-bottom: 12px;
}
.headTextPolis{
  margin-bottom: 60px;
}
.headTextPolis h1 {
  margin-bottom: 0;
}
.headText div {
  display: flex;
  justify-content: center;
}
.headText h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 45px;
  width: 50%;
}
.headText p {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
}
.services {
  margin-bottom: 20px;
}
.serviceBox {
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 20px;
}
.serviceBox div {
  text-align: center;
  cursor: pointer;
  padding: 18px 11px 24px 11px;
}
.serviceBox div:hover {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.serviceBox p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  margin-top: 10px;
}
.serviceText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.serviceText div {
  margin-bottom: 15px;
  display: flex;
  align-items: baseline;
  width: 550px;
}

.serviceText h1 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
}
.serviceText img {
  margin-right: 28px;
}
.serviceText div:last-child {
  margin-bottom: 0;
}

.formBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 40px;
 
}

.formBlock input {
  margin-right: 16px;
  margin-top: 0;
  margin-left: 0;
}
.background {
  background-image: url(../image/Star_of_life.png);
  background-repeat: no-repeat;
  background-size: inherit;
  width: 100%;
  height: 883px;
  position: absolute;
  left: 0;
  top: 155%;
  z-index: -1;
}
.apps {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
}
.appMarket div {
  display: flex;
  column-gap: 20px;
}

.appMarket h1 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
  width: 500px;
  margin-bottom: 60px;
}
.mobileImg {
  position: relative;
}
.mobileImg img:first-child {
  max-width: 100%;
  width: 90%;
}
.mobileImg img:last-child {
  position: absolute;
  left: 30%;
  top: -5%;
  max-width: 100%;
  width: 100%;
}
/* .plusAdd {
  display: none;
} */

/* Media */

@media screen and (max-width: 1180px) {
  .serviceBox {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 20px;
  }
  .apps {
    flex-direction: column-reverse;
    row-gap: 30px;
  }
  .serviceText {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 950px) {
  .serviceBox {
    grid-template-columns: repeat(2, 1fr);
  }
  .serviceBox div {
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }
  /* .plusAdd {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .plusAdd img {
    width: 37px !important;
    height: 37px !important;
  } */
}
@media screen and (max-width: 850px) {
  .background {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .headText {
    margin-top: 40px;
    margin-bottom: 45px;
  }
  .headText h1 {
    font-size: 24px;
    line-height: 28px;
  }
  .headText div {
    margin-bottom: 12px;
  }
  .headText h3 {
    font-size: 16px;
    width: 100%;
    line-height: 19px;
    margin-bottom: 0;
  }

  .serviceBox div {
    padding: 14px 8px 18px 8px;
  }
  .appMarket h1 {
    font-size: 16px;
    text-align: center;
    line-height: 19px;
    margin-bottom: 25px;
  }
  .appMarket div {
    justify-content: center;
  }
  .formBlock {
    justify-content: space-around;
    column-gap: 20px;
  }
}
@media screen and (max-width: 580px) {
  .serviceText div {
    width: 100%;
    align-items: center;
  }
  .appMarket h1 {
    width: 100%;
  }
  .appMarket div {
    justify-content: center;
  }
}
@media screen and (max-width: 420px) {
  /* .serviceBox {
    column-gap: 10px;
  }
  .serviceBox div{
    height: 80%;
  }
  .serviceBox p {
    font-size: 12px;
  }
  .serviceBox img {
    width: 70%;
    
  }
  .plusAdd {
    height: 190px;
  } */
  .formBlock {
    display: grid;
    row-gap: 20px;
  }
  .serviceBox {
    grid-template-columns: repeat(1, 1fr);
  }
  .serviceBox div {
    padding: 0;
    text-align: left;
    background: transparent;
    box-shadow: none;
    border-radius: 0px;
  }
  .serviceBox p {
    margin-top: 0;
  }
  .serviceBox p::before {
    content: "\2022";
    display: inline-block;
    width: 20px;
  }

  .serviceBox br {
    display: none;
  }
  /* .plusAdd {
    display: none;
  } */
  .serviceBox img {
    display: none;
  }
  .serviceText h1 {
    font-size: 16px;
  }
  .appMarket img {
    width: 100%;
  }
  .mobileImg img:first-child {
    width: 70%;
  }
  .mobileImg img:last-child {
    width: 80%;
    left: 25%;
  }
}
