.saveBtn {
  background: #00ccff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  font-weight: 400;
  font-size: 20px;
  cursor: pointer;
  text-transform: uppercase;
  color: #ffffff;
  padding: 20px 60px;
}
.cancelBtn {
  color: red;
  font-size: 16px;
  cursor: pointer;
}
.formBtn {
  display: flex;
  justify-content: center;
  column-gap: 30px;
}
.resultStep{
  margin-top: 40px;
  text-align: center;
}
.resultStep h1 {
  color: #008d0e;
  font-size: 60px;
  font-weight: 400;
}
.resultStep h2 {
  margin-top: 10px;
  color: grey;
  font-size: 50px;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 500px) {
  .saveBtn {
    padding: 20px 30px;
  }
  .resultStep h1 {
    font-size: 30px;
  }
  .resultStep h2 {
    font-size: 16px;
  }
}
