.slide1 {
  background: url(./image/slide1.png);
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position-x: center;
  background-repeat: no-repeat;
}
.slide2 {
  background: url(./image/slide2.jpg);
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position-x: center;
  background-repeat: no-repeat;
}
.slide3 {
  background: url(./image/slide3.jpg);
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position-x: center;
  background-repeat: no-repeat;
}
.logo img{
  width: 100%;
}
.slide {
  position: relative;
}
.slideText {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.slideText h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 40px;
  line-height: 47px;
  text-transform: uppercase;
  margin-bottom: 40px;
  color: #ffffff;
}
.slideText p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
}
.logo {
  display: flex;
  column-gap: 40px;
  padding-top: 40px;
  padding-left: 40px;
}
.social {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  position: absolute;
  top: 50%;
  left: 85%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.slick-prev {
  z-index: 2;
  left: 90px !important;
  height: auto !important;
  width: auto !important;
}
.slick-next {
  z-index: 2;
  right: 90px !important;
  height: auto !important;
  width: auto !important;
}
.slick-next:before {
  content: url(./image/arrowRight.svg) !important;
}
.slick-prev:before {
  content: url(./image/arrowLeft.svg) !important;
}
.slick-dots {
  bottom: 25px !important;
}
.slick-dots li button:before{
	color: #fff !important;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: transparent;
  border-radius: 50%;
  background: white;
  
  border: 2px solid #ffffff;
  width: 12px;
  height: 12px;
}
/* Media */
@media screen and (max-width: 1100px) {
  .slick-prev {
    display: none !important;
  }
  .slick-next {
    display: none !important;
  }
  .social {
    flex-direction: row;
    column-gap: 20px;
    top: 20%;
    left: 50%;
  }
}
@media screen and (max-width: 750px) {
  .logo {
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;
  }
  .slideText h1 {
    font-size: 36px;
    line-height: 42px;
  }
  .slideText p {
    font-size: 18px;
    line-height: 21px;
  }
}
@media screen and (max-width:420px) {
	/* .slideText h1{
		font-size: 20px;
		line-height: 30px;
	}
	.slideText p{
		font-size: 14px;
	} */

	/* .social{
		top: 30%;
	} */
}